<script lang="ts" setup>
import type { MenuItem } from 'primevue/menuitem';

const { baseUrl } = useRuntimeConfig().public;
const { data: session, status, signOut, signIn } = useAuth();
const features = useFeaturesStore();
const organisation = useOrganisationStore();

const organisationId = session.value?.user.organisationId;

await callOnce(() => organisation.loadUsers(organisationId));

const navigation = [
    {
        label: 'Home',
        route: `${baseUrl}/dashboard`,
        external: true,
        icon: 'prime:home',
        visible: !!session.value?.user.organisationId || session.value?.user.role === 'admin',
    },
    {
        label: 'Data Check-in',
        route: `${baseUrl}/data-checkin-pipelines`,
        external: true,
        icon: 'prime:cloud-upload',
        visible: !!session.value?.user.organisationId,
    },
    {
        label: 'My Assets',
        route: `${baseUrl}/assets`,
        external: true,
        icon: 'prime:th-large',
        visible: !!session.value?.user.organisationId,
    },
    {
        label: 'Analytics',
        route: `${baseUrl}/analytics-pipelines`,
        external: true,
        icon: 'prime:share-alt',
        visible: features.isEnabled('analytics') && session.value?.user.organisationId,
    },
    {
        label: 'Dashboards',
        route: `/dashboards`,
        icon: 'carbon:dashboard',
        visible: features.isEnabled('dashboard.builder') && session.value?.user.organisationId,
    },
    {
        label: 'Retrieval',
        route: `${baseUrl}/retrieval`,
        external: true,
        icon: 'prime:cloud-download',
        visible:
            features.areAnyEnabled(['retrieve.api', 'retrieve.files', 'retrieve.stream']) &&
            session.value?.user.organisationId,
    },
    {
        label: 'Data Models',
        route: `${baseUrl}/models`,
        external: true,
        icon: 'prime:objects-column',
        visible:
            features.isEnabled('model-manager') &&
            ['admin', 'model-moderator'].includes(session.value?.user.role ?? ''),
    },
    {
        label: 'Admin',
        route: `${baseUrl}/admin`,
        external: true,
        icon: 'ri:admin-line',
        visible: session.value?.user.role === 'admin',
    },
];

// User Menu
const userMenu = ref();
const userProfileNavigation: MenuItem[] = [
    { label: 'My Profile', command: () => navigateTo('/profile'), icon: 'pi pi-user' },
    { label: 'Sign Out', command: () => signOut({ callbackUrl: '/' }), icon: 'pi pi-sign-out' },
];
const toggleUserMenu = (event: any) => {
    userMenu.value.toggle(event);
};

// const gotoNotifications = () => console.log('NYI - gotoNotifications');
</script>

<template>
    <div class="flex flex-1 flex-col overflow-hidden">
        <div
            class="fixed inset-y-0 left-0 z-50 block w-20 overflow-y-auto border-r border-primary-900 bg-primary-950 pb-4"
        >
            <div class="flex h-16 shrink-0 items-center justify-center">
                <img class="h-8 w-auto" src="/images/griffo.png" alt="GRIFFO" />
            </div>
            <nav class="mt-8">
                <ul role="list" class="flex flex-col items-center space-y-1">
                    <li v-for="(item, idx) in navigation" :key="idx">
                        <NuxtLink
                            v-if="item.visible"
                            v-tooltip="item.label"
                            :to="item.route"
                            :external="item.external"
                            active-class="bg-primary-900 !text-white"
                            class="group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6 text-white/50 hover:bg-primary-900 hover:text-white"
                        >
                            <Icon :name="item.icon ?? ''" class="h-6 w-6" />
                        </NuxtLink>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="flex flex-col pl-20 overflow-hidden h-screen">
            <PDynamicDialog />
            <div
                class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-surface-200 bg-white dark:border-surface-700 dark:bg-surface-900 px-4 sm:gap-x-6"
            >
                <div class="flex flex-1 flex-row-reverse gap-x-4 self-stretch lg:gap-x-6">
                    <div class="flex items-center gap-x-4">
                        <!-- View Data Model(s) Icon -->
                        <button
                            v-if="status === 'authenticated'"
                            type="button"
                            class="rounded-full p-1.5 w-9 h-9 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                        >
                            <Icon name="heroicons:book-open" class="text-neutral-400 w-6 h-6" />
                        </button>

                        <!-- Notifications Icon -->
                        <button
                            v-if="status === 'authenticated'"
                            type="button"
                            class="rounded-full p-1.5 w-9 h-9 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                        >
                            <Icon name="heroicons:bell" class="text-neutral-400 w-6 h-6" />
                        </button>

                        <!-- Light/Dark Mode Picker -->
                        <ColorModePicker v-if="features.isEnabled('ui.dark')" />

                        <!-- Separator -->
                        <div class="block h-6 w-px bg-surface-900/10 dark:bg-surface-700" aria-hidden="true"></div>

                        <!-- Profile dropdown -->
                        <div class="flex flex-row space-x-2 items-center">
                            <div v-if="status === 'authenticated'" class="relative">
                                <button
                                    type="button"
                                    class="flex flex-row items-center !p-1 rounded-full space-x-2"
                                    aria-haspopup="true"
                                    aria-controls="user_menu"
                                    @click="toggleUserMenu"
                                >
                                    <PAvatar shape="circle">
                                        <template #icon>
                                            <Icon
                                                name="prime:user"
                                                class="text-primary-700 w-5 h-5 dark:text-primary-200"
                                            />
                                        </template>
                                    </PAvatar>
                                    <div class="text-sm text-neutral-800 dark:text-neutral-200">
                                        {{ session?.user?.name }}
                                    </div>
                                    <Icon
                                        name="prime:chevron-down"
                                        class="w-4 h-4 text-neutral-800 dark:text-neutral-200"
                                    />
                                </button>

                                <PMenu id="user_menu" ref="userMenu" :popup="true" :model="userProfileNavigation" />
                            </div>
                            <PButton
                                v-else
                                size="small"
                                label="Sign In&nbsp;"
                                :loading="status === 'loading'"
                                severity="secondary"
                                @click="signIn('keycloak')"
                            >
                                <template #icon>
                                    <Icon name="prime:sign-in" />
                                </template>
                            </PButton>
                        </div>
                    </div>
                </div>
            </div>

            <main class="flex flex-col flex-1 flex-grow flex-shrink overflow-hidden h-screen">
                <slot />
            </main>
        </div>
        <PToast position="bottom-right" />
    </div>
</template>
